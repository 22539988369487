import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import AdminPanel from './Components/AdminPanel/AdminPanel';
import Login from './Components/AdminPanel/Login/Login';
import './App.css';
import NavPanel from './Components/AdminPanel/SubPanel/NavPanel';
import AboutPanel from './Components/AdminPanel/SubPanel/AboutPanel';
import FactPanel from './Components/AdminPanel/SubPanel/FactPanel';
import ServicePanel from './Components/AdminPanel/SubPanel/ServicePanel';
import WhyChooseUsPanel from './Components/AdminPanel/SubPanel/WhychooseusPanel';
import DoctorPanel from './Components/AdminPanel/SubPanel/DoctorPanel';
import CTAPanel from './Components/AdminPanel/SubPanel/CTApanel';
import PartnerPanel from './Components/AdminPanel/SubPanel/PartnerPanel';
import BlogPanel from './Components/AdminPanel/SubPanel/BlogPanel';
import FooterPanel from './Components/AdminPanel/SubPanel/FooterPanel';
import AboutPagePanel from './Components/AdminPanel/PagePanel/AboutPagePanel';
import GalleryPanel from './Components/AdminPanel/PagePanel/GalleryPanel';
import ServicePagePanel from './Components/AdminPanel/PagePanel/ServicePagePanel';
import BannerPanel from './Components/AdminPanel/SubPanel/BannerPanel';
import MapVideoPanel from './Components/AdminPanel/PagePanel/MapVideoPanel';
 




const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>

          {/* Login Page Route */}
        <Route
          path="/*"
          element={<Login setLoggedIn={setLoggedIn} />}
        />

        {/* Admin Panel Route */}
        <Route
          path="/admin"
          element={loggedIn ? <AdminPanel /> : <Navigate to="/login" />}
        />

   

<Route exact path='/admin/nav-panel' element={loggedIn ? <NavPanel/> : <Navigate to="/login" />} /> 
       <Route exact path='/admin/about-section' element={loggedIn ? <AboutPanel/> : <Navigate to="/login" />} />
       <Route exact path='/admin/funFacts' element={loggedIn ? <FactPanel/> : <Navigate to="/login" />} />
       <Route exact path='/admin/OurSpecialisation' element={loggedIn ? <ServicePanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/wycu' element={loggedIn ? <WhyChooseUsPanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/services' element={loggedIn ? <DoctorPanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/cta' element={loggedIn ? <CTAPanel/> : <Navigate to="/login" />}/> 
       <Route exact path='/admin/partners' element={loggedIn ? <PartnerPanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/blog' element={loggedIn ? <BlogPanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/footer' element={loggedIn ?<FooterPanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/about-page' element={loggedIn ?<AboutPagePanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/gallery' element={loggedIn ?<GalleryPanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/Service-page' element={loggedIn ?<ServicePagePanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/banner' element={loggedIn ?<BannerPanel/> : <Navigate to="/login" />}/>
       <Route exact path='/admin/ContactUs' element={loggedIn ?<MapVideoPanel/> : <Navigate to="/login" />}/>

        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;