import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import axiosInstance from '../../../axiosInstance.js';
import './Panel.css';
import {SideBar} from "../SideBar/SideBar.js";
import { Navbar } from '../Navbar/Navbar.js';

const PartnerPanel = () => {
  const [partners, setPartners] = useState([]); 
  const [newPartnerImage, setNewPartnerImage] = useState(null);

  // Function to fetch partners from backend using Axios
  const fetchPartners = async () => {
    try {
      const response = await axiosInstance.get('/partner/content');
      setPartners(response.data);
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  
  useEffect(() => {
    fetchPartners();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewPartnerImage(file);
  };

  const handleAddPartner = async () => {
    try {
      if (!newPartnerImage) {
        throw new Error('Please select an image file');
      }

      const formData = new FormData();
      formData.append('PartnerImage', newPartnerImage);

      const response = await axiosInstance.post('/partner/upload', formData);

      if (response.status === 200) {
        fetchPartners(); 
        setNewPartnerImage(null); 
      } else {
        throw new Error('Failed to add partner');
      }
    } catch (error) {
      console.error('Error adding partner:', error);
    }
  };

  // Function to handle deleting a partner
  const handleDeletePartner = async (partnerId) => {
    try {
      const response = await axiosInstance.delete(`/partner/${partnerId}`);

      if (response.status === 200) {
        fetchPartners(); 
      } else {
        throw new Error('Failed to delete partner');
      }
    } catch (error) {
      console.error('Error deleting partner:', error);
    }
  };


  return (
    <><Navbar /><SideBar /><div className="panel-container">
   
      <div className="panel-form">
        <div>
          {/* Input for adding new partner image */}
          <h1>Partner Images</h1>
          <div className="partner-add-form">
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleAddPartner}>Add Partner</button>
          </div>

          {/* List of current partners with delete button */}
          <div className="partner-list">
            {partners.map((partner) => (
              <div className="partner-item" key={partner._id}>
                <img src={partner.PartnerImage} alt={`Partner ${partner._id}`} />
                <button onClick={() => handleDeletePartner(partner._id)}>Delete</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div></>
  );
};

export default PartnerPanel;
