import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance.js';
import {SideBar} from "../SideBar/SideBar.js";
import { Navbar } from '../Navbar/Navbar.js';

const MobileBannerVideoPanel = () => {
  const [videoId, setVideoId] = useState(null);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axiosInstance.get('/bannervideo/mobile');
        const videoData = response.data;
        
        if (videoData && videoData.length > 0) {
          setVideoId(videoData[0]._id);
          setCurrentVideoUrl(videoData[0].Video);
        }
      } catch (error) {
        console.error('Failed to fetch video data:', error);
        setErrorMessage('Failed to fetch video data. Please try again later.');
      }
    };

    fetchVideoData();
  }, []);

  const handleFileChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const handleVideoUpdate = async () => {
    if (!videoFile) {
      setErrorMessage('Please upload a video file');
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('Video', videoFile);

      const response = await axiosInstance.put(`/bannervideo/mobile/${videoId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Video updated:', response.data);
      
      setCurrentVideoUrl(response.data.Video);
    } catch (error) {
      console.error('Failed to update video:', error);
      setErrorMessage('Failed to update video. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='banner-video'>
      <h2>Edit Mobile Banner Video</h2>
      {currentVideoUrl && (
        <video controls>
          <source src={currentVideoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <input type="file" accept="video/*" onChange={handleFileChange} />
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <button onClick={handleVideoUpdate} disabled={loading}>
        {loading ? 'Updating...' : 'Update Video'}
      </button>
    </div>
  );
};

export default MobileBannerVideoPanel;
