import React from 'react';
export const Navbar = () => {
  return (
    <header className="admin-header">
      <div className="admin-header__brand">
        <h1>Admin Portal</h1>
      </div>
      <nav className="admin-header__nav">
        <ul>
          <li><a href="#">Dashboard</a></li>
          <li><a href="#">Users</a></li>
          <li><a href="#">Settings</a></li>
          {/* Add more navigation items as needed */}
        </ul>
      </nav>
    </header>
  )
}
